import React from "react";

import { Link } from "gatsby";
import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";

import Menu from "react-burger-menu/lib/menus/slide";

import { COLORS } from "../../utils/theme";

import { StyledComponentsBreakpoints } from "./Responsive";

const linkColor = COLORS.lightgrey.hex;

class TopNavigation extends React.Component {
  constructor(props) {
    super(props);

    const links = [
      {
        title: "News",
        slug: "news",
        children: [
          { title: "#FEST22 News", slug: "news" }
        ],
      },
      {
        title: "Store",
        slug: "store",
        children: [
          {
            title: "Buy FEST 22 Single Show Tickets (SEETICKETS.US)",
            slug: "see-tickets-single-show",
            url: "https://www.Seetickets.us/thefestfl",
          },
          {
            title: "Buy FEST 22 Thurs Bo Diddley Kickoff Party Tickets (SEETICKETS.US)",
            slug: "see-tickets-thursday-show",
            url: "https://wl.seetickets.us/event/KICKOFF-SHOW-Bo-Diddley-Plaza/598497?afflky=TheFest",
          },

          {
            title: "SOLD OUT! Buy Your FEST22 3-Day Pass (SEETICKETS.US)",
            slug: "see-tickets-fest22-pass",
            url: "https://wl.seetickets.us/event/THE-FEST-22/591038?afflky=TheFest",
          },
          {
            title: "Book Your OFFICIAL FEST 22 Hotel Room (LIMITEDRUN.COM)",
            slug: "limited-run",
            url: "https://thefestfl.limitedrun.com/categories/hotels",
          },
          /*
          */
          {
            title: "Buy Mailorder FEST Merch",
            slug: "mailorder-fest-merch",
            url: "https://pillowheadmerch.com/collections/the-fest-merch",
          },
          /*
          {
            title: "SOLD OUT! - FEST 21 3-Day Passes(SEETICKETS.US)",
            slug: "see-tickets",
            url: "https://wl.seetickets.us/wafform.aspx?_act=eventtickets&_pky=550547&afflky=TheFest",
          },
          */
        ],
      },
      {
        title: "Lineup",
        slug: "lineup",
        children: [
          { title: "Schedule", slug: "schedule" },
          { title: "Bands", slug: "bands" },
          { title: "FEST Comedy Showcase", slug: "comedy" },
          { title: "FEST Wrestling", slug: "fest-wrestling" },
          { title: "Drag Show", slug: "beatdown-dragged-out" },
          { title: "Bandcamp Friday", slug: "bandcamp" },
        ],
      },
      {
        title: "Experience",
        slug: "experience",
        children: [
          { title: "What is FEST?", slug: "what-is-fest" },
          { title: "Download The FEST 22 App", slug: "app" },
          { title: "Registration Info", slug: "registration" },
          { title: "Flea Market", slug: "flea-market" },
          { title: "Volunteer For A Pass", slug: "volunteer" },
          //{ title: "Venue Info", slug: "venues" },
        ],
      },
      {
        title: "Travel Guide",
        slug: "travel-guide",
        children: [
          { title: "Official FEST Hotels", slug: "hotels", url: "https://thefestfl.limitedrun.com/categories/hotels" },
          {
            title: "Parking",
            slug: "parking-info",
            url: "https://www.gainesvillefl.gov/Government-Pages/Government/Departments/Transportation/GNV-Parking",
          },
          { title: "Local Eats", slug: "local-eats" },
          { title: "Visit Gainesville", slug: "visit-gainesville" },
        ],
      },
      {
        title: "More Info",
        slug: "more-info",
        children: [
          { title: "FAQ", slug: "faq" },
          { title: "Contact Us", slug: "contact" },
          { title: "FEST Photo/Video Team Info", slug: "fest-photography" },
          {
            title: "FEST Photo Archive",
            slug: "fest-photo-archive",
            url: "https://www.flickr.com/people/thefestfl/",
          },
          { title: "Social Impact", slug: "social-impact" }
        ],
      },
    ];
    this.state = { showOverlayMenu: false, links };

    this.toggleOverlayMenu = this.toggleOverlayMenu.bind(this);
  }

  buildPageNodes() {
    const { links } = this.state;

    const pageNodes = [];

    links.forEach(page => {
      const pageNode = {
        name: page.title,
        pagePath: `/${page.slug}`,
        url: page.url,
        id: page.slug
      };

      if (page.children) {
        pageNode.children = [];

        page.children.forEach(childPage => {
          const childPageNode = {
            name: childPage.title,
            pagePath: `/${childPage.slug}`,
            url: childPage.url,
            id: `child-${childPage.slug}`
          };
          pageNode.children.push(childPageNode);
        });
      }

      pageNodes.push(pageNode);
    });
    return pageNodes;
  }

  handleOverlayMenuStateChange(state) {
    this.setState({ showOverlayMenu: state.isOpen });
  }

  toggleOverlayMenu() {
    const { showOverlayMenu } = this.state;
    this.setState({
      showOverlayMenu: !showOverlayMenu
    });
  }

  render() {
    const { noLogo } = this.props;
    const { showOverlayMenu } = this.state;
    const links = this.buildPageNodes();

    return (
      <FestNavigation>
        <NavStripe />

        <Phone>
          <Menu
            right
            isOpen={showOverlayMenu}
            customCrossIcon={false}
            onStateChange={state => this.handleOverlayMenuStateChange(state)}
            width="50%"
            styles={{
              bmBurgerButton: {
                position: "fixed",
                width: "30px",
                height: "20px",
                right: "36px",
                top: "28px"
              },
              bmBurgerBars: { background: "#a6a8ab" },
              bmCrossButton: { height: "24px", width: "24px" },
              bmCross: { background: "#a6a8ab" },
              bmMenu: {
                background: "rgba(21, 23, 23, 0.9)",
                fontSize: "1.15em"
              },
              bmItemList: { color: "#b8b7ad" },
              bmOverlay: { background: "rgba(0, 0, 0, 0.3)" }
            }}
          >
            <BurgerNav>
              <BurgerNavList>
                {links.map(node => {
                  if (node.children) {
                    const toggleName = `dropdown-${node.id}`;
                    return (
                      <li key={node.id}>
                        <label htmlFor={toggleName} className="toggle">
                          {node.name}
                        </label>

                        <input type="checkbox" id={toggleName} />
                        <ul>
                          {node.children.map(subnode => (
                            <li key={subnode.id}>
                              {typeof subnode.url !== "undefined" && (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={subnode.url}
                                >
                                  {subnode.name}
                                </a>
                              )}

                              {!subnode.url && (
                                <Link to={subnode.pagePath}>
                                  {subnode.name}
                                </Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      </li>
                    );
                  }
                  return (
                    <li key={node.id}>
                      <Link to={node.pagePath}>{node.name}</Link>
                    </li>
                  );
                })}
              </BurgerNavList>
            </BurgerNav>
          </Menu>
        </Phone>

        <NavigationContainer>
          <TitleSection>
            <Phone>
              <Link to="/">
                <LogoContainer>
                  <picture id="nav-logo">
                    <img
                      src="/images/logos/fest22-nav.png"
                      alt="THE FEST 22"
                    />
                  </picture>
                </LogoContainer>
              </Link>
            </Phone>
            <NotPhone>
              {!noLogo && (
                <Link to="/">
                  <LogoContainer>
                    <picture id="nav-logo">
                      <img
                        src="/images/logos/fest22-nav.png"
                        alt="THE FEST 22"
                      />
                    </picture>
                  </LogoContainer>
                </Link>
              )}
            </NotPhone>
          </TitleSection>
          <NotPhone>
            <NavList>
              {links.map(node => {
                if (node.children && node.children.length > 0) {
                  return (
                    <li key={node.id}>
                      <a>
                        <div>{node.name}</div>
                      </a>
                      <div>
                        <ul>
                          {node.children.map(subnode => (
                            <li key={subnode.id}>
                              {typeof subnode.url !== "undefined" && (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={subnode.url}
                                >
                                  {subnode.name}
                                </a>
                              )}

                              {!subnode.url && (
                                <Link to={subnode.pagePath}>
                                  {subnode.name}
                                </Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                  );
                }

                return (
                  <li key={node.id}>
                    <Link to={node.pagePath}>
                      <div>{node.name}</div>
                    </Link>
                  </li>
                );
              })}
            </NavList>
          </NotPhone>
        </NavigationContainer>
      </FestNavigation>
    );
  }
}

const fadeInAnimation = keyframes`${fadeIn}`;

const Phone = styled.div`
  ${StyledComponentsBreakpoints.Phone`
    display:inherit;
  `};

  ${StyledComponentsBreakpoints.NotPhone`
    display:none;
  `};
`;
const NotPhone = styled.div`
  ${StyledComponentsBreakpoints.Phone`
    display:none;
  `};

  ${StyledComponentsBreakpoints.NotPhone`
    display:inherit;
  `};
`;

const FestNavigation = styled.div`
  z-index: 1200;
  position: relative;
  background: ${COLORS.black.rgba.replace(", 1)", ", 0.311)")} !important;


  ${StyledComponentsBreakpoints.Phone`
    height: 72px;
    max-height: 72px;
    background: ${COLORS.black.rgba.replace(", 1)", ", 0.96024)")} !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 0px 2px 2px rgba(137, 137, 137, 0.3);
  `};
`;
const NavigationContainer = styled.div`
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 100%;
  max-width: 1024px;
`;
const NavStripe = styled.div`
  animation: 0.311s ${fadeInAnimation};
  /* background: linear-gradient(90deg, #26627c 0, #6649ca 30%, #1b3552 60%, #6649ca 100%); */
  min-height: 2px;
  width: 100%;
`;

const LogoContainer = styled.div`
  animation: 0.420s ${fadeInAnimation};

  & img {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 4px;

    max-height: 48px;

    @media (max-width: 1000px) {
      margin-left: 36px;
    }
    @media (max-width: 900px) {

      margin-left: 32px;
    }

    @media (max-width: 800px) {
      margin-left: 24px;
    }

    ${StyledComponentsBreakpoints.Phone`
      margin-top: 7px;
      margin-left: 12px;
      max-height: 57px;
    `};
  }
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${StyledComponentsBreakpoints.Phone`
    min-height: 70px;
  `};

  ${StyledComponentsBreakpoints.NotPhone`
    min-height: 48px;
  `};

  h3 {
    font-size: 2.5rem;
  }

  div {
    margin-right: 10px;
  }
`;

const BurgerNav = styled.nav`
  margin: 0;
  padding: 0;
  outline: none;


  &:after {
    content: "";
    display: table;
    clear: both;
  }
`;

const BurgerNavList = styled.ul`
  margin-bottom: 0;
  float: right;
  padding: 0;
  list-style: none;
  position: relative;

  [id^="dropdown"] {
    display: none;
  }
  li {
    margin: 0px;
    display: inline-block;
    float: right;
    text-align: right;
  }
  .toggle,
  a {
    cursor: pointer;

    font-family: "Rubik", "League Gothic", "Rubik", "Roboto Condensed", "Roboto", -apple-system, BlinkMacSystemFont,
      "segoe ui", arial, "ubuntu", sans-serif;
    color: ${linkColor};
    font-size: 20px;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 311ms ease;

    &:hover {
      color: ${linkColor};
    }
  }

  @media (max-width: 360px) {
    .toggle,
    a {
      font-size: 20px
    }
  }

  a {
    display: block;
    padding: 14px 20px;
    text-decoration: none;
  }

  ul {
    display: none;
    position: absolute;
    /* has to be the same number as the "line-height" of "nav a" */
    top: 60px;

    > li {
      width: 170px;
      float: none;
      display: list-item;
      position: relative;
    }
  }

  > li:hover > ul {
    display: inherit;
  }

  .toggle + a,
  .menu {
    display: none;
  }
  .toggle {
    display: block;
    padding: 14px 36px 14px 20px;
    border: none;
    &:after {
      position: absolute;
      right: 16px;
      content: " +";
    }
  }

  > li {
    > a:hover,
    > .toggle:hover {
      background-color: rgba(21, 23, 23, 0.9);
    }
  }

  [id^="dropdown"]:checked + ul {
    display: block;
  }

  li {
    display: block;
    width: 100%;
  }

  ul .toggle,
  ul a {
    padding: 0 40px;
  }
  li ul li .toggle,
  ul a {
    margin-right: 8px;
    padding: 12px 0;
    color: #fff;
    font-size: 16px;
  }

  ul {
    float: none;
    position: static;
    color: #ffffff;
    /* has to be the same number as the "line-height" of "nav a" */
    margin-bottom: 0;
  }

  ul li:hover > ul,
  li:hover > ul {
    display: none;
  }

  ul li {
    display: block;
    width: 100%;
  }

  ul {
    text-align: right;
    padding: 4px 8px 4px 26px;
    background: #fff;
    box-shadow: 0px 0px 0px 2px rgba(23, 23, 23, 0.2);
    margin-top: 2px;
    > li > a {
      color: rgba(23, 23, 23, 1);
      font-size: 15px;
      hyphens: none;

      &:hover {
        color: ${COLORS.red.hex}
      }
    }
  }
`;
const NavList = styled.ul`
  z-index: 1201;
  animation: 1.666s ${fadeInAnimation};

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;

  /* main nav item state */
  > li {
    flex: 0 1 auto;
    margin: 0;
    padding: 0;
    position: relative;
    transition: all linear 0.1s;

    > a {
      padding: 4px 14px;
      color: rgba(255, 255, 255, 0.75);
      text-transform: uppercase;
      align-items: flex-start;
      display: flex;
      text-decoration: none;
      transition: all linear 0.1s;
      white-space: nowrap;

      > div {
        font-family: "Rubik", "League Gothic", "Rubik", "Roboto Condensed", "Roboto", -apple-system,
          BlinkMacSystemFont, "segoe ui", arial, "ubuntu", sans-serif;
        text-transform: uppercase;
        font-size: 15px;
        text-decoration: none;
        letter-spacing: 0.1px;
      }
    }
  }
  /* nav item hover state */
  > li:hover {
    > a {
      color: ${COLORS.white.hex};
    }
  }

  /* hide dropdowns by default */
  > li a + div {
    background: #6d6e70;
    box-shadow: 0px 0px 0px 2px rgba(21, 23, 23, 0.1);

    display: none;
    position: absolute;
  }

  /* show dropdown on hover */
  > li:hover a + div {
    display: block;
    right: 0;
    z-index: 1000;
  }

  /* hide dropdown item bullets */
  > li a + div > ul {
    list-style-type: none;
    margin-bottom: 0;
  }

  /* dropdown list items */
  li a + div > ul > li {
    margin: 0;
    padding: 0;
    text-align: right;
    > a {
      display: block;
      white-space: nowrap;
      padding: 12px 16px 12px 24px;

      font-family: "Rubik", "League Gothic", "Rubik", "Roboto Condensed", "Roboto", -apple-system,
        BlinkMacSystemFont, "segoe ui", arial, "ubuntu", sans-serif;
      text-transform: uppercase;
      text-decoration: none;

      font-size: 12px;
      letter-spacing: 0.1px;

      color: #ddd;
    }
  }

  /* dropdown list item hover state */
  > li a + div > ul > li:hover > a {
    background-color: ${COLORS.white.hex};
    color: ${COLORS.black.hex};
  }

  /* MEDIA QUERIES FOR RESPONSIVE SIZES */
  @media (max-width: 950px) {
    margin-right: 18px;
    > li > a {
      padding: 4px 12px;
    }
    li a + div > ul > li > a {
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }
`;

export default TopNavigation;
